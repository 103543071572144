import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

import Arrow from '../../images/ui/arrow-2.svg'
import Clock from '../../images/symbols/clock.svg'

import { articleCardSizes } from '../../functions/ArticleUtils'

const ArticleCard = ({
    article: { title, slug, label, description, image, readingTime },
    size = articleCardSizes.MEDIUM
}) => {
    const baseDescriptionHeight = 29;
    const baseTitleHeight = 25;
    const [titleHeight, setTitleHeight] = useState(baseTitleHeight);
    const [descriptionHeight, setDescriptionHeight] = useState(baseDescriptionHeight);
    const maxDescriptionHeight = baseDescriptionHeight * (4 - Math.ceil(titleHeight / baseTitleHeight) >= 0 ? Math.ceil(titleHeight / baseTitleHeight) : 0);
    const showReadMore = Math.floor(descriptionHeight) > Math.ceil(maxDescriptionHeight);

    const measuredRef = useCallback((setter) => (node) => {
        if (node !== null) {
            setTimeout(() => setter(node.getBoundingClientRect().height), 500);
        }
    }, []);

    return (
        <Link
            to={`/articles/${slug}`}
            className="flex h-full w-full group"
            style={{ maxHeight: size === articleCardSizes.SMALL ? 'calc(100% / 2 - 1rem)' : 'auto' }}
        >
            <div className={`flex flex-grow border-2 border-secondary shadow-md hover:shadow-xl transition-quick ${size === articleCardSizes.MEDIUM ? 'flex-col-reverse' : ''}`}>
                {size !== articleCardSizes.SMALL && image && (
                    <img
                        className={`${size === articleCardSizes.LARGE ? 'w-1/2' : ''} ${size === articleCardSizes.MEDIUM ? 'mt-0' : ''} object-cover m-4`}
                        src={image.url}
                        style={{
                            height: size === articleCardSizes.LARGE ? 'auto' : '250px',
                            width: size === articleCardSizes.LARGE ? 'calc(50% - 3rem)' : 'auto',
                        }}
                        alt={image.title}
                    />
                )}
                <div className={`${size === articleCardSizes.LARGE ? 'w-1/2' : ''} m-4 flex flex-col flex-grow`}>
                    <div className="flex-grow">
                        <h4 ref={measuredRef(setTitleHeight)} className="font-bold mb-2">{title}</h4>
                        {size !== articleCardSizes.SMALL && description && (
                            <div className="relative overflow-hidden" style={{ maxHeight: `${maxDescriptionHeight}px` }}>
                                <p ref={measuredRef(setDescriptionHeight)} className="text-md mb-0">{description}</p>
                                {showReadMore && (
                                    <p className="text-md mb-0" style={{ position: 'absolute', bottom: 0, right: 0, paddingLeft: '5rem', paddingRight: '1rem', background: 'linear-gradient(to right, transparent 0%, white 5rem)' }}>Read more »</p>
                                )}
                            </div>
                        )}
                    </div>
                    <div className={`flex flex-shrink-0 ${size !== articleCardSizes.LARGE ? 'flex-col' : 'flex-row items-center'} -m-1`}>
                        <div className="flex items-center flex-shrink-0 m-1">
                            <img src={Clock} className="h-8 w-8 mr-1" alt="clock" />
                            <span className="text-xs">{`${readingTime} min${readingTime > 1 ? 's' : ''}`}</span>
                        </div>
                        <div className={`flex ${label ? 'justify-between' : 'justify-end'} flex-grow m-1`}>
                            {label && (
                                <div className="bg-primary rounded-md px-2 py-1">
                                    <span className="text-xs uppercase text-white">{label}</span>
                                </div>
                            )}
                            <img
                                src={Arrow}
                                className="inline transform transition-quick group-hover:scale-125"
                                alt="Arrow"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </Link>
    );
};

ArticleCard.propTypes = {
    article: PropTypes.shape({
        title: PropTypes.string.isRequired,
        slug: PropTypes.string.isRequired,
        label: PropTypes.string,
        description: PropTypes.string,
        image: PropTypes.shape({
            url: PropTypes.string
        }),
        readingTime: PropTypes.number
    }).isRequired,
    size: PropTypes.oneOf(Object.values(articleCardSizes))
}

ArticleCard.defaultProps = {
    size: articleCardSizes.MEDIUM
}

export default ArticleCard
