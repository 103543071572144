import { BLOCKS } from '@contentful/rich-text-types';

export const lgWidth = 1024
export const mdWidth = 768

export const articleCardSizes = {
    SMALL: 'small',
    MEDIUM: 'medium',
    LARGE: 'large',
}

export const getArticleImage = (content) => (
    content?.json?.content
        ?.find(({ nodeType }) => nodeType === BLOCKS.EMBEDDED_ASSET)
        ?.data.target.fields.file['en-US']
)

export const getArticleCardSize = (image, windowWidth) => {
    if (image) {
        if (image.details.image.height > image.details.image.width && windowWidth > lgWidth) {
            return articleCardSizes.LARGE
        }

        return articleCardSizes.MEDIUM
    }

    return articleCardSizes.SMALL
}

export const getBlocksFromArticles = (articles, { onlySizes, limit } = { onlySizes: Object.values(articleCardSizes), limit: Infinity }) => (
    articles
        .reduce((acc, article, index, array) => {
            const hasBeenAdded = acc.find((accItem) => (
                Array.isArray(accItem) ? accItem.find(({ id }) => id === article.id) : article.id === accItem.id
            ))

            const image = getArticleImage(article.content)
            const size = getArticleCardSize(image)

            if (hasBeenAdded || !onlySizes.includes(size) || acc.length === limit) {
                return acc
            }

            const label = article?.knowledge_hub_sections?.[0]?.title
            const description = article.content?.json?.content?.[0]?.content?.[0]?.value
            const wordsPerMinute = 200
            const wordCount = article.content.json.content
                .filter(({ nodeType }) => (
                    [
                        BLOCKS.HEADING_1,
                        BLOCKS.HEADING_2,
                        BLOCKS.HEADING_3,
                        BLOCKS.HEADING_4,
                        BLOCKS.HEADING_5,
                        BLOCKS.HEADING_6,
                        BLOCKS.PARAGRAPH
                    ].includes(nodeType)
                ))
                .reduce((contentWordCount, { content }) => (
                    contentWordCount + content.reduce((subContentWordCount, { value }) => (
                        subContentWordCount + value ? value.split(' ').filter((item) => item).length : 0
                    ), 0)
                ), 0)
            const readingTime = Math.ceil(wordCount / wordsPerMinute)
            const additions = { image, size, label, description, readingTime }
            const articleWithAdditions = { ...article, ...additions }

            if (size === articleCardSizes.SMALL) {
                const nextArticleImage = getArticleImage(array?.[index + 1]?.content)
                const nextArticleSmall = getArticleCardSize(nextArticleImage) === articleCardSizes.SMALL
                const prevArticlePaired = Array.isArray(acc?.[acc.length - 1])

                if (nextArticleSmall) {
                    return [...acc, [articleWithAdditions, { ...array[index + 1], ...additions }]]
                }

                if (prevArticlePaired) {
                    const pairIndex = array.findIndex((arrayItem, arrayItemIndex) => {
                        const pairImage = getArticleImage(arrayItem?.content)
                        const pairSize = getArticleCardSize(pairImage)

                        return arrayItemIndex > index + 1 && pairSize === articleCardSizes.SMALL
                    })

                    return pairIndex > 0
                        ? [...acc, [articleWithAdditions, { ...array[pairIndex], ...additions }]]
                        : [...acc, articleWithAdditions]
                }
            }

            return [...acc, articleWithAdditions]
        }, [])
)
