import { useEffect, useState } from 'react'

function useWindowSize() {
    const [windowSize, setWindowSize] = useState({
        width: undefined,
        height: undefined,
    })

    useEffect(() => {
        function handleResize() {
            setWindowSize({
                width: window?.innerWidth,
                height: window?.innerHeight,
            })
        }

        if (window) {
            window.addEventListener('resize', handleResize)
            handleResize()
        }

        return () => {
            if (window) {
                window.removeEventListener('resize', handleResize)
            }
        }
    }, [])

    return windowSize
}

export default useWindowSize
