import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import ArticleCard from '../knowledgehub/ArticleCard'
import useWindowSize from '../../functions/useWindowSize'
import { articleCardSizes, getBlocksFromArticles, lgWidth, mdWidth } from '../../functions/ArticleUtils'

const BlogPosts = () => {
    const windowSize = useWindowSize()
    const columnCount = windowSize.width > lgWidth ? 3 : (windowSize.width > mdWidth ? 2 : 1)
    const { allContentfulKnowledgeHubArticles } = useStaticQuery(graphql`
        query {
            allContentfulKnowledgeHubArticles(sort: { fields: updatedAt, order: DESC }, limit: 20) {
                nodes {
                    id
                    title
                    slug
                    content {
                        json
                    }
                    updatedAt
                    knowledge_hub_sections {
                        title
                    }
                }
            }
        }
    `)

    const articlesToBlocks = getBlocksFromArticles(
        allContentfulKnowledgeHubArticles?.nodes,
        {
            onlySizes: [articleCardSizes.MEDIUM],
            limit: 3
        }
    )

    if (!articlesToBlocks?.length) {
        return null
    }

    return (
        <div
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-delay="500"
            className="w-full max-w-6xl mx-auto px-2 my-16 flex flex-wrap -m-4"
        >
            {articlesToBlocks.map((block) => (
                <div
                    key={block?.id || block.map(({ id }) => id).join()}
                    data-aos="fade-up"
                    data-aos-duration="1000"
                    className=" flex flex-col justify-between m-4"
                    style={{
                        height: '500px',
                        flex: `0 0 calc(100% / ${columnCount} - 2rem)`
                    }}
                >
                    <ArticleCard article={block} size="medium" />
                </div>
            ))}
        </div>
    )
}

export default BlogPosts
